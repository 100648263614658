import { useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

// types
import { GuardProps } from 'types/auth';

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }: GuardProps) => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({
        appState: {
          returnTo: window.location.href
        }
      })
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (!isLoading && isAuthenticated) {
    return children;
  }

  return (
    <div>
      Loading...
    </div>
  )
};

export default AuthGuard;
