/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

import { Box, Chip } from '@mui/material';
import logo from 'assets/images/logo.png';

const LogoMain = ({ reverse, ...others }: { reverse?: boolean }) => {
  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === ThemeMode.DARK ? logoDark : logo} alt="icon logo" width="100" />
     *
     */
    <>
      <Box sx={{ paddingTop: 3, paddingBottom: 2 }}>
        <Box>
          <img style={{ marginBottom: -9 }} src={logo} alt="icon logo" width="32" />
          <span style={{ fontWeight: 600, fontSize: 20, marginBottom: 5 }}>Defenda</span>
          <Chip style={{ marginLeft: 5, marginBottom: 3 }} variant='outlined' size="small" label="beta" />
        </Box>
        <Box>
          <Box sx={{ paddingLeft: '4px', color: 'grey' }}>Security Intelligence</Box>
        </Box>
      </Box>
    </>
  );
};

export default LogoMain;
