import * as Sentry from "@sentry/react";

import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// third-party
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

// fonts
import "assets/fonts/inter/inter.css";

// scroll bar
import "simplebar/dist/simplebar.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// apex-chart
import "assets/third-party/apex-chart.css";
import "assets/third-party/react-table.css";

// project-imports
import App from "./App";
import { store, persister } from "store";
import { Auth0Provider } from "@auth0/auth0-react";
import { ConfigProvider } from "contexts/ConfigContext";
import reportWebVitals from "./reportWebVitals";

Sentry.init({
  dsn: "https://daf80e06eab2ffbc10f89834460ffb24@o4507335699922944.ingest.de.sentry.io/4507335802617936",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^\/defenda/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: window.location.host 
});

const container = document.getElementById("root");
const root = createRoot(container!);

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

root.render(
  <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persister}>
      <ConfigProvider>
        <Auth0Provider
          domain="defenda.eu.auth0.com"
          clientId="LCXsBocY0qyb9CIayOdIDkjOERa03YuU"
          useRefreshTokens
          cacheLocation="localstorage"
          authorizationParams={{
            redirect_uri: window.location.origin,
          }}
        >
          <BrowserRouter basename={process.env.REACT_APP_BASE_NAME}>
            <App />
          </BrowserRouter>
        </Auth0Provider>
      </ConfigProvider>
    </PersistGate>
  </ReduxProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
