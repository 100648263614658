// third-party

// assets
import { Brodcast, Code, KeySquare } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';


// ==============================|| MENU ITEMS - CHARTS & MAPS ||============================== //

const integrations: NavItemType = {
  id: 'integrations',
  title: 'Integrations',
  icon: Brodcast,
  type: 'group',
  children: [
    {
      id: 'cve-intel-cve',
      title: "API",
      search: "API",
      type: 'item',
      icon: Code,
      url: '/restricted',
    },
    {
      id: 'cve-intel-cve',
      title: "API Keys",
      type: 'item',
      icon: KeySquare,
      url: '/restricted',
    },
  ]
};

export default integrations;
