// third-party

// assets
import { Book1, BoxTick, Brodcast } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';


// ==============================|| MENU ITEMS - CHARTS & MAPS ||============================== //

const compliance: NavItemType = {
  id: 'compliance',
  title: 'Compliance',
  icon: Brodcast,
  type: 'group',
  children: [
    {
      id: 'compliance',
      title: "Service Rulebook",
      type: 'item',
      icon: Book1,
      url: '/restricted',
    },
    {
        id: 'compliance',
        title: "Service Assessment",
        type: 'item',
        icon: BoxTick,
        url: '/restricted',
      },
  ]
};

export default compliance;
