// project-imports
import compliance from './compliance';
import cveIntel from './cve-intel';
import integrations from './integration';
import inventory from './inventory';

// types
import { NavItemType } from 'types/menu';
import services from './services';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [cveIntel, inventory, compliance, integrations, services]
};

export default menuItems;
