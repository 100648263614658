import {
  Alert,
  Chip,
  Divider,
  Grid,
  LinearProgress,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Book1,
  Box,
  Code,
  Flash,
  InfoCircle,
  Status,
  TickCircle,
} from "iconsax-react";
import { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router";
import TimeAgo from "react-timeago";
import { flag } from "country-emoji";
import { CVE, Version, Affected, MetasploitModule, ExploitDbEntry } from "types/cve";
import { CVSSChip } from "components/CVSSChip";
import { EPSSChip } from "components/EPSSChip";

const CommonVulnerabilities = () => {
  const [loading, setLoading] = useState(true);
  const [cve, setCve] = useState<Partial<CVE>>({});
  const { cveId } = useParams<{ cveId: string }>();



  const hasExploit = useMemo(
    () =>
      (cve?.metasploit?.modules?.length || 0) > 0 ||
      (cve?.exploitDb?.entries?.length || 0) > 0,
    [cve]
  );

  const hasRemediations = useMemo(
    () => (cve?.remediations?.length || 0) > 0,
    [cve]
  );



  const exploitabilityScore = useMemo(
    () => cve?.epss?.exploitabilityScore,
    [cve]
  );



  const validVersions = useMemo(
    () =>
      cve?.affected?.filter(
        (affected: Affected) =>
          affected.vendor || affected.product || affected.versions
      ),
    [cve]
  );
  const cweId = useMemo(() => cve?.cwe?.id?.split("-")[1], [cve]);
  const cweUrl = useMemo(
    () => `https://cwe.mitre.org/data/definitions/${cweId}.html`,
    [cweId]
  );

  const versions: { product: string; vendor: string; version: string }[] = [];

  validVersions?.forEach((affected: Affected) => {
    const product = affected.product || "";
    const vendor = affected.vendor || "";
    const affectedVersions: string[] = [];

    affected.versions
      ?.filter((version: Version) => version.status === "affected")
      .forEach((version: Version) => {
        let versionText = version.version || "";

        if (version.lessThanOrEqual) {
          versionText = `<= ${version.lessThanOrEqual}`;
        }

        if (version.lessThan) {
          versionText = `< ${version.lessThan}`;
        }

        affectedVersions.push(versionText);
      });

    versions.push({
      product,
      vendor,
      version: affectedVersions.join(", "),
    });
  });

  const [vulnerabilities, setVulnerabilities] = useState([] as any);

  useEffect(() => {
    fetch('https://eu.staging.free-api.defenda.se/cve/kev?limit=10')
      .then((response) => response.json())
      .then((data) => {
        setVulnerabilities(data);
      });
  }, []);

  console.log(vulnerabilities);
  

  useEffect(() => {
    fetch(`${process.env.REACT_APP_DEFENDA_API_URL}/cve/${cveId}`)
      .then((response) => response.json())
      .then((data) => {
        setCve(data);
        setLoading(false);
      });
  }, [cveId]);

  if (loading) {
    return (
      <>
        <LinearProgress />
      </>
    );
  }

  return (
    <>
      {cve.title !== cve.id && (
        <div>
          <Typography variant="h6">{cve.id}</Typography>
          <Typography variant="h3" style={{ marginTop: "8px" }}>
            {cve.title}
          </Typography>
        </div>
      )}

      {cve.title === cve.id && (
        <div>
          <Typography variant="h3">{cve.title}</Typography>
        </div>
      )}

      {cve.publishedAt && (
        <div style={{ color: "grey", float: "left" }}>
          <Tooltip title={cve.publishedAt}>
            <span>
              Published <TimeAgo title="published" date={cve.publishedAt} />
            </span>
          </Tooltip>
        </div>
      )}

      <div style={{ marginTop: "10px", lineHeight: 3 }}>
        {cve?.cvss?.baseScore && (
          <Tooltip title="The Common Vulnerability Scoring System (CVSS) score is a numerical rating ranging from 0 to 10 that reflects the severity of a system security vulnerability, with 0 indicating no risk and 10 indicating the highest level of risk.">
            <CVSSChip score={cve.cvss.baseScore} label="CVSS" />
          </Tooltip>
        )}

        {exploitabilityScore && (
          <Tooltip title="The EPSS model produces a probability score between 0 and 1 (0 and 100%). The higher the score, the greater the probability that a vulnerability will be exploited.">
             <EPSSChip score={cve?.epss?.exploitabilityScore} label="EPSS" />
          </Tooltip>
        )}

        {cve?.cisa?.isUsedInRansomwareCampaign && (
          <Tooltip title="According to CISA this vulnerability is being used by criminal groups to collect ransom throgh ransomware attacks.">
            <Chip
              icon={<InfoCircle />}
              variant="outlined"
              label="Used in Ransomware Campaign"
              color="warning"
              style={{ marginRight: "10px" }}
              sx={{ mb: 1, ml:1 }}
            />
          </Tooltip>
        )}

        {(cve?.cisa?.isActivelyExploited || cve?.exploitedInTheWild) && (
          <Tooltip title="CISA.gov detected that this vulnerability is being actively exploited by a variety of threat actors.">
            <Chip
              icon={<InfoCircle />}
              variant="outlined"
              label="Actively Exploited"
              color="warning"
              style={{ marginRight: "10px" }}
              sx={{ mb: 1, ml:1 }}
            />
          </Tooltip>
        )}

        {hasExploit && (
          <Tooltip title="We have found a public exploit for this vulnerability. This means that it is public knowledge how to take advantage of and exploit this vulnerability.">
            <Chip
              icon={<InfoCircle />}
              variant="outlined"
              label="Public Exploit Found"
              color="warning"
              style={{ marginRight: "5px" }}
            />
          </Tooltip>
        )}
      </div>

      <div style={{ marginTop: 20 }}>
        <Paper elevation={0} sx={{ padding: 3 }}>
          <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
            {cve?.description}
          </Typography>
        </Paper>
      </div>

      {hasRemediations && (
        <div style={{ marginTop: 20 }}>
          {cve.remediations?.map((remediation: string) => (
            <Alert
              severity="warning"
              sx={{ paddingTop: "5px", marginTop: "5px" }}
            >
              <div style={{ marginTop: 3 }}>{remediation}</div>
            </Alert>
          ))}
        </div>
      )}

      {versions.length > 0 && (
        <>
          <Divider style={{ marginTop: "20px", marginBottom: "10px" }} />

          <h3>
            <Box style={{ marginBottom: -5, marginRight: 5 }} /> Affected
            products and versions
          </h3>

          <TableContainer component={Paper} style={{ marginTop: 5 }}>
            <Table aria-label="simple table">
              <TableHead style={{ backgroundColor: "#23272e" }}>
                <TableRow>
                  <TableCell>Vendor</TableCell>
                  <TableCell align="right">Product</TableCell>
                  <TableCell align="right">Version</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {versions.map((version: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {version.vendor}
                    </TableCell>
                    <TableCell align="right">{version.product}</TableCell>
                    <TableCell align="right">{version.version}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      {cve?.cvss?.vector && cve?.cvss?.vectorString && (
        <>
          <Divider style={{ marginTop: "20px", marginBottom: "10px" }} />
          <h3>
            <Status style={{ marginBottom: -6, marginRight: 5 }} /> CVSS
            Breakdown
          </h3>

          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2, md: 3 }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            columns={{ xs: 6 * 2, sm: 6 * 3, md: 6 * 3 }}
            alignItems="stretch"
          >
            <Grid item xs={6}>
              <Paper style={{ padding: 10 }}>
                <b>Attack vector</b>
                <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                <Chip label={cve?.cvss?.vector?.attackVector} />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper style={{ padding: 10 }}>
                <b>Attack complexity</b>
                <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                <Chip label={cve?.cvss?.vector?.attackComplexity} />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper style={{ padding: 10 }}>
                <b>Privileges required</b>
                <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                <Chip label={cve?.cvss?.vector?.privilegesRequired} />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper style={{ padding: 10 }}>
                <b>User interaction</b>
                <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                <Chip label={cve?.cvss?.vector?.userInteraction} />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper style={{ padding: 10 }}>
                <b>Scope</b>
                <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                <Chip label={cve?.cvss?.vector?.scope} />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper style={{ padding: 10 }}>
                <b>Confidentiality impact</b>
                <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                <Chip label={cve?.cvss?.vector?.confidentialityImpact} />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper style={{ padding: 10 }}>
                <b>Integrity impact</b>
                <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                <Chip label={cve?.cvss?.vector?.integrityImpact} />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper style={{ padding: 10 }}>
                <b>Availability impact</b>
                <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                <Chip label={cve?.cvss?.vector?.availabilityImpact} />
              </Paper>
            </Grid>
          </Grid>
        </>
      )}

      {cve?.threatActorsLastWeek && (
        <>
          <Divider style={{ marginTop: "20px", marginBottom: "10px" }} />
          <h3>
            <Flash style={{ marginBottom: -6, marginRight: 5 }} /> Threat
            activity in the past week
          </h3>

          <Typography variant="body1" style={{ marginBottom: "10px" }}>
            Threat actors with IP Addresses from the locations below have been
            idenfitied by ShadowServer trying to exploit this vulnerability in
            the past week.
          </Typography>

          <Paper elevation={0} style={{ padding: "20px" }}>
            {Object.keys(cve?.threatActorsLastWeek ?? {}).map(
              (actor: string) => {
                let ipAddresses = 0;

                if (
                  cve?.threatActorsLastWeek &&
                  cve?.threatActorsLastWeek[actor]
                ) {
                  ipAddresses = cve.threatActorsLastWeek[actor];
                }

                return (
                  <Chip
                    label={`${flag(
                      actor
                    )} ${actor} - ${ipAddresses} IP addresses`}
                    style={{ marginRight: "10px", marginBottom: "10px" }}
                  />
                );
              }
            )}
          </Paper>
        </>
      )}

      {(cve?.exploitDb?.entries?.length || 0) > 0 && (
        <>
          <Divider style={{ marginTop: "20px", marginBottom: "10px" }} />
          <h3>
            <Code style={{ marginBottom: -6, marginRight: 5 }} /> Exploit
            Database Entries
          </h3>
          {cve?.exploitDb?.entries?.map((entry: ExploitDbEntry) => (
            <Paper elevation={0} style={{ padding: "20px", marginBottom: 0 }}>
              <Typography variant="h5" style={{ marginBottom: 5 }}>
                {entry.description}
              </Typography>

              <Chip
                variant="outlined"
                size="small"
                label={`${entry.author}`}
                style={{ marginRight: "10px" }}
              />

              <Chip
                variant="outlined"
                size="small"
                label={`${entry.type}`}
                style={{ marginRight: "10px" }}
              />

              <Chip
                variant="outlined"
                size="small"
                label={`${entry.platform}`}
                style={{ marginRight: "10px" }}
              />

              {entry.verified && (
                <Chip
                  icon={<TickCircle />}
                  variant="outlined"
                  size="small"
                  label="Verified"
                />
              )}

              <Divider style={{ marginTop: "10px", marginBottom: "15px" }} />

              <Link
                href={entry.url}
                target="_blank"
                style={{ textDecoration: "none", marginRight: 20 }}
              >
                Exploit Database Entry
              </Link>

              {entry.sourceUrl && (
                <Link
                  href={entry.sourceUrl}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  Exploit Source
                </Link>
              )}
            </Paper>
          ))}
        </>
      )}

      {(cve?.metasploit?.modules?.length || 0) > 0 && (
        <>
          <Divider style={{ marginTop: "20px", marginBottom: "10px" }} />
          <h3>
            <Code style={{ marginBottom: -6, marginRight: 5 }} /> Metasploit
            modules
          </h3>
          Metasploit is a powerful tool that can be used by cybersecurity
          professionals to test system vulnerabilities and, in the wrong hands,
          could potentially be used to exploit weaknesses and break into
          systems.
          {cve?.metasploit?.modules?.map((module: MetasploitModule) => (
            <Paper
              elevation={0}
              style={{ padding: "20px", marginTop: "20px", marginBottom: 0 }}
            >
              <Typography variant="h5">{module.name}</Typography>
              <Divider style={{ marginTop: "10px", marginBottom: "15px" }} />
              <Typography variant="h6">{module.description}</Typography>

              <br />

              <Link
                href={`https://github.com/rapid7/metasploit-framework/tree/master${module.path}`}
                target="_blank"
              >
                Exploit code
              </Link>
            </Paper>
          ))}
        </>
      )}

      {cve?.cwe?.id && (
        <>
          <Divider style={{ marginTop: "20px", marginBottom: "10px" }} />

          <h3>
            <Book1 style={{ marginBottom: -6, marginRight: 5 }} /> How does this
            vulnerability happens?
          </h3>

          <Paper elevation={0} style={{ padding: "20px" }}>
            <Link
              href={cweUrl}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <Typography variant="h6">{cve?.cwe?.id}</Typography>
            </Link>

            <Typography variant="h5">{cve?.cwe?.title}</Typography>

            <Divider style={{ marginTop: "10px", marginBottom: "15px" }} />

            <Typography variant="body1">{cve?.cwe?.description}</Typography>

            {cve?.cwe?.descriptionExtended && (
              <Typography variant="body1" style={{ marginTop: 20 }}>
                {cve.cwe.descriptionExtended}
              </Typography>
            )}
          </Paper>
        </>
      )}

      {(cve?.cwe?.relatedAttackPatterns?.length || 0) > 0 && (
        <>
          <Divider style={{ marginTop: "20px", marginBottom: "10px" }} />
          <h3>
            <Flash style={{ marginBottom: -6, marginRight: 5 }} />
            Related attack patterns
          </h3>

          {cve?.cwe?.relatedAttackPatterns?.map((pattern: any) => {
            const capecId = pattern.id.split("-")[1];
            const capecUrl = `https://capec.mitre.org/data/definitions/${capecId}.html`;

            return (
              <Paper
                elevation={0}
                style={{
                  padding: "20px",
                  marginTop: "10px",
                  marginBottom: 20,
                }}
              >
                <Link
                  href={capecUrl}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <Typography variant="h6">{pattern.id}</Typography>
                </Link>
                <Typography variant="h5">{pattern.title}</Typography>

                <div style={{ marginTop: 5, lineHeight: 2 }}>
                  {pattern.likelihoodOfAttack && (
                    <Chip
                      label={`${pattern.likelihoodOfAttack} typical likelihood of attack`}
                      style={{ marginRight: "10px" }}
                      variant="outlined"
                      size="small"
                    />
                  )}
                  {pattern.typicalSeverity && (
                    <Chip
                      label={`${pattern.typicalSeverity} typical severity`}
                      variant="outlined"
                      size="small"
                    />
                  )}
                </div>

                <Divider style={{ marginTop: "15px", marginBottom: "15px" }} />
                <Typography variant="body1">{pattern.description}</Typography>
              </Paper>
            );
          })}
        </>
      )}
    </>
  );
};

export default CommonVulnerabilities;
