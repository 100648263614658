import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Chip from '@mui/material/Chip';

interface EPSSChipProps {
  score: any;
  label: string;
}

export const EPSSChip: React.FC<EPSSChipProps> = ({ score, label }) => {
  const getChipColor = (score: number) => {
    if (score >= 0.7) return 'error';
    if (score >= 0.4) return 'warning';
    return 'success';
  };

  if (score === undefined) {
    return null;
  }

  return (
    console.log(score),
    <Chip
      variant="outlined"
      sx={{ml:1, mb:1, p:1}}
      icon={<InfoOutlinedIcon color={getChipColor(score)} />}
      label={`${label}: ${score}`}
    />
  );
  
};