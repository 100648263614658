// third-party

// assets
import { Bag, Brodcast } from "iconsax-react";

// type
import { NavItemType } from "types/menu";

// ==============================|| MENU ITEMS - CHARTS & MAPS ||============================== //

const services: NavItemType = {
  id: "services",
  title: "Services",
  icon: Brodcast,
  type: "group",
  children: [
    {
      id: "penetration-testing",
      title: "Penetration Testing",
      type: "item",
      icon: Bag,
      url: "/restricted",
    },
    {
      id: "security-training",
      title: "Security Training",
      type: "item",
      icon: Bag,
      url: "/restricted",
    },
    {
      id: "threat-modeling",
      title: "Threat Modeling",
      type: "item",
      icon: Bag,
      url: "/restricted",
    },
    {
        id: "consultancy",
        title: "Consultancy",
        type: "item",
        icon: Bag,
        url: "/restricted",
      },
  ],
};

export default services;
