import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Chip from '@mui/material/Chip';

interface CVSSChipProps {
  score: number;
  label: string;
}

export const CVSSChip: React.FC<CVSSChipProps> = ({ score, label }) => {
  const getChipColor = (score: number) => {
    if (score >= 7) return 'error';
    if (score >= 4) return 'warning';
    return 'success';
  };

  return (
    <Chip
      variant="outlined"
      sx={{ml:1, mb:1, p:1}}
      icon={<InfoOutlinedIcon color={getChipColor(score)} />}
      label={`${label}: ${score}`}
    />
  );

};