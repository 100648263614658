// third-party

// assets

import {
  Brodcast,
  Convert3DCube,
  Cpu,
  I3DCubeScan,
  UserOctagon,
} from "iconsax-react";

// type
import { NavItemType } from "types/menu";

// ==============================|| MENU ITEMS - CHARTS & MAPS ||============================== //

const inventory: NavItemType = {
  id: "inventory",
  title: "Organization",
  icon: Brodcast,
  type: "group",
  children: [
    {
      id: "org-units",
      title: "Units",
      chip: {
        variant: 'outlined',
        color: 'primary',
        label: '0',
        size: 'small'
      },
      type: "item",
      icon: UserOctagon,
      url: "/inventory/org-units",
    },
    {
      id: "systems",
      title: "Systems",
      chip: {
        variant: 'outlined',
        color: 'primary',
        label: '0',
        size: 'small'
      },
      type: "item",
      icon: Convert3DCube,
      url: "/inventory/systems",
    },
    {
      id: "systems",
      title: "Services",
      chip: {
        variant: 'outlined',
        color: 'primary',
        label: '0',
        size: 'small'
      },
      type: "item",
      icon: I3DCubeScan,
      url: "/inventory/services",
    },
    {
      id: "assets",
      title: "Assets",
      chip: {
        variant: 'outlined',
        color: 'primary',
        label: '0',
        size: 'small'
      },
      type: "item",
      icon: Cpu,
      url: "/inventory/assets",
    },
  ],
};

export default inventory;
