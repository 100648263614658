import { Typography } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';

interface PublishedAtProps {
  date: string;
}

export const PublishedAt: React.FC<PublishedAtProps> = ({ date }) => {
  const formattedDate = formatDistanceToNow(new Date(date), { addSuffix: true });

  return <Typography variant="h6" sx={{p:1}}>{formattedDate}</Typography>;
};