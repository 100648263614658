/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoIconDark from 'assets/images/logo-icon-dark.svg';
 * import logoIcon from 'assets/images/logo-icon.svg';
 *
 */

// ==============================|| LOGO ICON SVG ||============================== //

import logo from 'assets/images/logo.png';

const LogoIcon = () => {
  return (
    <img
      src={logo}
      alt="Logo"
      width="32"
      height="32"
    />
  );
};

export default LogoIcon;
