import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import { Card, CardContent, Typography, Chip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { CVSSChip } from "components/CVSSChip";
import { EPSSChip } from "components/EPSSChip";

import { PublishedAt } from "components/PublishedAtChip";

const CveSearch: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://probable-space-broccoli-rq5gp747x4gfv79-8080.app.github.dev/cve/kev?limit=50"
      );
      const result = await response.json();
      setData(result);
      setLoading(false);
    };

    fetchData();
  }, []);

  


  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    // Validação do formato do CVE ID
    const isValid = /^CVE-\d{4}-\d{4,}$/.test(searchTerm);



    if (!isValid) {
      setErrorMessage("Por favor, insira um CVE ID válido.");
    } else {
      setErrorMessage("");
      navigate(`${searchTerm}`);
      // ... código para lidar com a submissão ...
    }
  };

  return (
    <div>
      {!!errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <Grid
        columns={{ xs: 4, sm: 8, md: 12 }}
        container
        spacing={{ xs: 2, md: 3 }}
        direction="row"
        justifyContent="start"
        alignItems="center"
        component="form"
        noValidate
        autoComplete="on"
        onSubmit={handleSubmit}
      >
        <Grid item xs={4} sm={6} md={10}>
          <TextField
            autoFocus
            sx={{ p: 1, width: "100%" }}
            id="outlined"
            placeholder="Search CVE"
            variant="outlined"
            onChange={(e) => setSearchTerm(e.target.value)}
            error={!!errorMessage}
          />
        </Grid>
        <Grid item xs={1} sm={2} md={2}>
          <Button
            type="submit"
            sx={{
              display: { xs: "none", sm: "flex" },
              ml: "-14px",
              width: "100%",
              height: "48px",
            }}
            variant="outlined"
            endIcon={<SearchIcon />}
          >
            Search
          </Button>
        </Grid>
      </Grid>

      <Typography variant="h2" sx={{ p: 1, mt: 5 }}>
        Recent Vulnerabilities
      </Typography>
      <Typography variant="h4" sx={{ p: 1, mb: 2 }}>
        Latest reported or recently changed vulnerabilities
      </Typography>

      <Grid container spacing={2}>
        {loading && <Typography>Loading...</Typography>}
        {!loading &&
          data.slice(0, 50).map((cve: any, index: number) => (
            <Grid item key={cve.id} xs={12} md={3}>
              <Card
                onClick={() => navigate(`${cve.id}`)}
                sx={{
                  height: "100%",
                  cursor: "pointer",
                  transition: "0.3s",
                  "&:hover": {
                    transform: "scale(1.05)",
                    boxShadow: "0 0 20px rgba(0,0,0,0.1)",
                    border: "1px solid rgba(0,0,0,0.1)", // Adiciona uma borda clara ao passar o mouse
                    backgroundColor: "#0d47a1", // Faz o card ficar mais claro ao passar o mouse
                  },
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    height: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography variant="h5" sx={{ p: 1 }}>
                      {cve.title ? cve.title : cve.id}
                    </Typography>
                    <PublishedAt date={cve.publishedAt} />
                    <CVSSChip score={cve.cvss.baseScore} label="CVSS" />
                    <EPSSChip score={cve.epss.exploitabilityScore} label="EPSS" />
                    {cve.cisa.isActivelyExploited && (
                      <Chip
                        sx={{ mb: 1, ml:1 }}
                        label="Actively Exploited"
                        icon={<InfoOutlinedIcon color="warning" />}
                        variant="outlined"
                      />
                    )}
                  </div>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    {cve.description.length > 200
                      ? `${cve.description.substring(0, 200)}...`
                      : cve.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

export default CveSearch;
