// third-party

// assets
import { Activity, Alarm, Brodcast, GlobalSearch } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';


// ==============================|| MENU ITEMS - CHARTS & MAPS ||============================== //

const cveIntel: NavItemType = {
  id: 'intel',
  title: "Intelligence",
  icon: Brodcast,
  type: 'group',
  children: [
    {
      id: 'intel-overview',
      title: "Overview",
      type: 'item',
      icon: Activity,
      url: '/restricted',
    },
    {
      id: 'intel-findings',
      title: "Findings",
      type: 'item',
      icon: Alarm,
      url: '/restricted',
      chip: {
        variant: 'outlined',
        color: 'success',
        label: '0',
        size: 'small'
      },
    },
    {
      id: 'intel-landscape',
      title: "Common Vulnerabilities",
      type: 'item',
      icon: GlobalSearch,
      url: '/osint/cve'
    },
  ]
};

export default cveIntel;
